<i18n src="./translations"></i18n>

<template>
  <div :class="classes" :data-preload="[$options.name]">
    <div class="widgetInner">
      <div v-if="!isLoggedIn">
        <div class="login">
          <Button
            icon="lock"
            :modifiers="['primary', 'full']"
            :title="$t('account-widget.login')"
            href="?rememberRedirectUrl=1"
          />
        </div>

        <div class="register">
          <span class="registerNew">
            {{ $t('account-widget.new_customer') }}?
          </span>

          <MaskedLink
            class="registerNow"
            :redirect-to="frontFacingPath('customer.shop.registration')"
            :text="$t('account-widget.register_now')"
          />
        </div>
      </div>

      <ul class="links">
        <li class="link-item">
          <MaskedLink
            class="link qa-myAccountLink"
            :redirect-to="frontFacingPath('customer.shop.customer_edit')"
            :text="$t('account-widget.my_account')"
          />
        </li>

        <li class="link-item">
          <MaskedLink
            class="link qa-myOrdersLink"
            :redirect-to="
              frontFacingPath('order.shop.overview') +
              '?page=1&limit=10&offset=0'
            "
            :text="$t('account-widget.my_orders')"
          />
        </li>

        <li class="link-item">
          <MaskedLink
            class="link qa-myWishlistLink"
            :redirect-to="frontFacingPath('customer.shop.wishlist')"
            :text="$t('account-widget.my_wishlist')"
          />
        </li>

        <li class="link-item">
          <MaskedLink
            class="link qa-myGarageLink"
            :redirect-to="frontFacingPath('customer.shop.vehicles')"
            :text="$t('account-widget.my_garage')"
          />
        </li>
      </ul>

      <div v-if="isLoggedIn" class="logout">
        <div v-if="!!username" class="user" :title="username">
          {{ username }}
        </div>
        <div v-else-if="!!email" class="user" :title="email">
          {{ email }}
        </div>

        <Button
          class="qa-logOutBtn"
          :modifiers="['primary', 'full']"
          :title="$t('account-widget.logout')"
          :href="frontFacingPath('customer.shop.logout')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'Components/01-atoms/button/Button.vue';
import globalMixin from 'Libs/mixins/globalMixin';
import MaskedLink from 'Components/01-atoms/masked-link/MaskedLink';

export default {
  name: 'AccountWidget',
  components: {
    Button,
    MaskedLink,
  },
  mixins: [globalMixin],
  props: {
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';

.accountWidget {
  transition: all 0.1s ease-in-out;
  width: 320px;
  background: var(--color-white);
  position: absolute;
  z-index: 6;
  top: 48px;
  left: 0;
  box-shadow: 0 10px 18px 0 rgba(51, 51, 51, 0.3);

  &::before {
    content: '';
    display: block;
    background: var(--color-white);
    position: absolute;
    top: -12px;
    left: 0;
    height: 12px;
    width: 128px;
  }
}

.widgetInner {
  padding: var(--space-1) var(--space-2) var(--space-2);
}

.login {
  padding-top: var(--space-1);
  padding-bottom: 10px;
}

.register {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--color-alto);
  margin-bottom: 12px;
  font-size: var(--font-size-SM);
  line-height: 22px;
  color: var(--color-black);
}

.registerNow {
  font-weight: bold;
  color: var(--color-secondary);
  text-transform: uppercase;
}

.links {
  font-size: var(--font-size-M);
  line-height: 30px;
  color: var(--color-black);
}

.logout {
  margin-top: 12px;
  border-top: 1px solid var(--color-alto);
  padding-top: 24px;
}

.user {
  padding-bottom: var(--space-1);
  margin-bottom: var(--space-1);
  font-weight: bold;
  color: var(--color-black);
  overflow-x: auto;
}
</style>
